<template>
    <ManterFavoritos 
        :matricula="currentUser.matricula"
    />
</template>

<script lang="js">
    import ManterFavoritos from '../../components/configuracoes/ManterFavoritos.vue';

    export  default {
        name: "manterFavoritos",
        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
	    emits: ['carregarFavoritos'],
        components: { ManterFavoritos }
    }
</script>