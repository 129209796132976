<template>
    <Toast />
    <div class="col-12">
        <div class="card">
            <h5>Favoritos</h5>

            <Message severity="info" :closable="false">Marque as funcionalidades que você deseja adicionar aos seus favoritos:</Message>

            <hr />

            <div class="grid">
                <div class="col-12 sm:col-6 md:col-4 lg:col-3" v-for="item in funcionalidades" :key="item.id">
                    <div class="field-checkbox">
                        <InputSwitch :id="item.codigo" v-model="item.isFavorito" @click="selecionado(item)" />
                        <label :for="item.codigo">{{ item.nome }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import FavoritoService from '../../service/FavoritoService';
    import FuncionalidadeService from '../../service/FuncionalidadeService';

    export default {
        props: {
			matricula: {
				type: Number,
				required: true
			}
		},
        
        data() {
			return {
                funcionalidades: [],
			}
		},
        
        created() {
            this.listarFuncionalidades();
		},

        methods: {
            async getFuncionalidadesPermiteFavorito() {
                const { data } = await FuncionalidadeService.listar();

                let itens;

                if(data) {
                    itens = data.filter((item) => {
                        // Filtrar as funcionalidades permitem ser listadas nos favoritos
                        return item.permiteFavorito
                    }).sort((func1, func2) => {
                        // Ordenar as funcionalidades em ordem alfabética
                        let nome1 = func1.nome;
                        let nome2 = func2.nome;

                        return nome1 == nome2 ? 0 : nome1 > nome2 ? 1 : -1;
                    });
                }

                return itens == undefined ? [] : itens;
            },

            async getFavoritos () {
                const { data } = await FavoritoService.listar(this.matricula);

                if(data) {
                    return data;
                }

                return [];
            },

            async listarFuncionalidades() {
                const favoritos = await this.getFavoritos();
                const funcionalidades = await this.getFuncionalidadesPermiteFavorito();

                this.funcionalidades = funcionalidades.map((funcionalidade) => {
                    const favorito = favoritos.find((favorito) => { 
                        return favorito.funcionalidade.id == funcionalidade.id
                    });

                    funcionalidade['isFavorito'] = favorito != undefined;
                    funcionalidade['idFavorito'] = favorito == undefined ? "" : favorito.id;

                    return funcionalidade;
                });
            },

            selecionado(funcionalidade) {
                if(funcionalidade.isFavorito && funcionalidade.idFavorito) {
                    FavoritoService.remover(funcionalidade.idFavorito).then(({ data }) => {
                        this.$toast.add({
                            severity:'success', 
                            summary: 'Sucesso',
                            detail: `${funcionalidade.nome} foi removido dos favoritos!`,
                            life: 3000
                        });

                        this.$parent.$emit('carregarFavoritos');
                    })
                    .catch((e) => {
                        funcionalidade.isFavorito = true;
                        this.$toast.add({
                            severity:'error',
                            summary: 'Falha',
                            detail: `Não foi possível remover "${funcionalidade.nome}" dos favoritos!`,
                            life: 3000
                        });
                    });
                }
                else {
                    FavoritoService.adicionar(this.matricula, funcionalidade.codigo)
                    .then(({ data }) => {
                        funcionalidade.idFavorito = data.id;
                        
                        this.$toast.add({
                            severity:'success', 
                            summary: 'Sucesso',
                            detail: `${funcionalidade.nome} foi adicionado aos favoritos!`,
                            life: 3000
                        });

                        this.$parent.$emit('carregarFavoritos');
                    })
                    .catch((e) => {
                        funcionalidade.isFavorito = false;
                        this.$toast.add({
                            severity:'error',
                            summary: 'Falha',
                            detail: `Não foi possível salvar "${funcionalidade.nome}" como favorito!`,
                            life: 3000
                        });
                    });
                }
            }
		}
    }
</script>